<template>
    <v-container fluid class="my-0 py-0">
        <v-row class="my-0 py-0" align="center" justify="center">
            <v-col cols="12" align="center">
                <v-carousel
                    cycle
                    height="400"
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                    <v-carousel-item v-for="(article, index) in articles" :key="index" @click="goToArticle(article)">
                        <v-card class="rounded-lg clickable">
                            <v-img
                                :src="getImage(article)"
                                aspect-ratio="1"
                                class="white--text align-end"
                                max-height="400px"
                            >
                                <v-card-title class="custom-orange text-h6" style="text-align: justify; word-break: break-word;" v-text="article.title" />
                                <v-card-subtitle class="custom-orange" style="text-align: end; word-break: break-word;"> {{ getDate(article) }} </v-card-subtitle>
                            </v-img>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
                <img
                    height="auto"
                    width="100%"
                    :src="require(`@/assets/slide1.jpg`)"
                    class="rounded-lg"
                    aspect-ratio="1"
                >
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(article, index) in articles" :key="index" cols="12" md="6">
                <article-card :article="article" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ArticleCard from '@/components/ArticleCard'
    import articleService from '@/services/article'

    export default {
        components: { ArticleCard },
        data() {
            return {
                page: 1,
                size: 7,
                totalPages: 0,
                articles: []
            }
        },
        created() {
            this.fetchArticles()
        },
        mounted() {
            this.loadMore()
        },
        methods: {
            goToArticle(article) {
                this.$router.push({ name: 'Article', params: { id: article.id } })
            },
            loadMore() {
                this.fetchNextData()
            },
            fetchNextData() {
                window.onscroll = () => {
                    const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
                    if (bottomOfWindow) {
                        if (this.page < this.totalPages) {
                            this.page += 1
                            this.fetchArticles()
                        }
                    }
                }
            },
            fetchArticles() {
                articleService.getArticles(this.page, this.size).then(resp => {
                    this.articles.push(...resp.data.data)
                    this.totalPages = resp.data.total
                })
            },
            getImage(article) {
                if (article.image) {
                    return `https://api.aofloisvos.gr/uploads/images/${article.image.imageName}`
                } else {
                    return require('@/assets/main_logo.png')
                }
            },
            getDate(article) {
                const date = new Date(article.articleDate)
                return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
            }
        }
    }
</script>

<style scoped>
.jumbotron-top {
    height: 65px;
}

.jumbotron {
    /* clip-path: polygon(0 0, 100% 1%, 96% 100%, 4% 100%); */
    background-color: white;
    height: 550px;
}

@media only screen and (max-width: 1000px) {
    .jumbotron {
        /* clip-path: polygon(0 0, 100% 1%, 96% 100%, 4% 100%); */
        background-color: white;
        height: 300px;
    }
}

.jumbotron-bottom {
    height: 55px;
    background-color: black;
}
</style>
