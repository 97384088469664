<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg clickable" @click="goToArticle(article)">
                    <v-img
                        :src="getArticleImage()"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="300px"
                        aspect-ratio="1"
                    >
                        <v-card-title class="custom-orange" style="text-align: justify; word-break: break-word;" v-text="article.title" />
                        <v-card-subtitle class="custom-orange" style="text-align: end; word-break: break-word;"> {{ getDate() }} </v-card-subtitle>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    // import momentTimezone from 'moment-timezone'
    export default {
        props: {
            article: {
                type: Object,
                default: null
            }
        },
        methods: {
            getArticleImage() {
                if (this.article.image) {
                    return `https://api.aofloisvos.gr/uploads/images/${this.article.image.imageName}`
                } else {
                    return require('@/assets/main_logo.png')
                }
            },
            goToArticle(article) {
                this.$router.push({ name: 'Article', params: { id: article.id } })
            },
            getDate() {
                const date = new Date(this.article.articleDate)
                return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
            }
        }
    }
</script>
